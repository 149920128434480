const steps = [
  {
    element: "#search-upload",
    popover: {
      title: "Search for Uploads",
      description: "Allows you to search for a specific upload(s)",
      position: "left",
    },
  },
  {
    element: "#upload-options",
    popover: {
      title: "Upload Options",
      description:
        "Allows you to switch between your documents and documents that are shared with you",
      position: "right",
    },
  },
  {
    element: "#upload-menu",
    popover: {
      title: "Upload Menu",
      description:
        "Menu that allows you to either add a new folder or file to the system",
      position: "right",
    },
  },
  {
    element: "#upload-table",
    popover: {
      title: "Upload Table",
      description: "Displays all relevent information for uploaded documents",
      position: "top",
    },
  },
  {
    element: "#table-row",
    popover: {
      title: "Upload Table - Interactable Row",
      description:
        "By clicking on a document within a row of the table, it opens up a preview of that file",
      position: "top",
    },
  },
];

export default steps;
